
html{
  font-family: Arial, Helvetica, sans-serif;
  font-variant: 1000;
  
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  width: 100%;
  overflow-x: hidden; /* Yatay kaydırmayı gizlemek için */
}

.baslik{
  font-family: 'Outfit';
  font-size: 32px;
  font-weight:bolder;
}

html a{
  text-decoration: none;
  color:black
}

html a:hover{
  color:#96c6d4
}



.css-zs58fr-MuiButtonBase-root-MuiButton-root a{
  text-decoration: none;
}



.css-zs58fr-MuiButtonBase-root-MuiButton-root a:hover{
  color: inherit; /* Varsayılan rengini koruması için */
  background-color: inherit; /* Varsayılan arka plan rengini koruması için */
  text-decoration: none; /* Alt çizgiyi kaldırmak için */
}